/* Layout: Based on Flexbox */
.horizontal,
.vertical,
.flex {
    display: flex;
}

/* Layout: Horizontal (left-to-right) */
.horizontal {
    flex-direction: row;
}

/* Layout: Horizontal (right-to-left) */
.horizontal-reverse {
    flex-direction: row-reverse;
}

/* Layout: Veritcal (top-to-bottom) */
.vertical {
    flex-direction: column;
}

/* Layout: Veritcal (bottom-to-top) */
.vertical-reverse {
    flex-direction: column-reverse;
}

.fill {
    width: 100%;
    height: 100%;
}
.fill-width {
    width: 100%;
}
.fill-height {
    height: 100%;
}

/* Convenience classes */
.centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: inherit;
    color: inherit;
    border-color: inherit;
}
