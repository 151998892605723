.gradient-default {
    background-image: linear-gradient(to bottom right, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-left-to-right {
    background-image: linear-gradient(to right, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-right-to-left {
    background-image: linear-gradient(to left, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-top-to-bottom {
    background-image: linear-gradient(to bottom, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-bottom-to-top {
    background-image: linear-gradient(to top, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-top-left-to-bottom-right {
    background-image: linear-gradient(to bottom right, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-bottom-left-to-top-right {
    background-image: linear-gradient(to top right, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-top-right-to-bottom-left {
    background-image: linear-gradient(to bottom left, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}

.gradient-bottom-right-to-top-left {
    background-image: linear-gradient(to top left, var(--gradient-start-color, inherit), var(--gradient-end-color, inherit));
}
