/* SCSS for controlling background color and color */

// Classes to darken/lighten the background color based on the underlying parent background color.
.darker-background-color {
    background-color: rgba(0, 0, 0, 0.1);
}
.lighter-background-color {
    background-color: rgba(255, 255, 255, 0.1);
}

// Classes to darken/lighten the color based on the underlying parent color.
.darker-text-color {
    color: rgba(0, 0, 0, 0.1);
}
.lighter-text-color {
    color: rgba(255, 255, 255, 0.1);
}

// Classes to darken/lighten the border color based on the underlying parent background color.
.darker-border-color {
    border-color: rgba(0, 0, 0, 0.1);
}
.lighter-border-color {
    border-color: rgba(255, 255, 255, 0.1);
}
