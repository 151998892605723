// Container
.jodit_theme_custom.jodit-container,
.jodit_theme_custom.jodit-container:not(.jodit_inline) {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: inherit;
    color: inherit;
    border-color: inherit;
}

// Tool bar
.jodit_theme_custom .jodit-toolbar__box {
    flex: 0 0 auto;
    background-color: var(--jodit-editor-toolbar-background-color, inherit) !important;
    color: var(--jodit-editor-toolbar-color, inherit) !important;
    border-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
    fill: var(--jodit-editor-toolbar-color, inherit) !important;
}
.jodit_theme_custom .jodit-toolbar__box svg {
    fill: var(--jodit-editor-toolbar-color, inherit) !important;
}
.jodit-toolbar-editor-collection_mode_horizontal {
    background-color: var(--jodit-editor-toolbar-background-color, inherit) !important;
    color: var(--jodit-editor-toolbar-color, inherit) !important;
    border-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
    background-image: none !important;
}
.jodit-toolbar-editor-collection_mode_horizontal:before {
    background-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
    background-image: none !important;
    top: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
}
.jodit-toolbar-editor-collection_mode_horizontal:after {
    background-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
    background-image: none !important;
    border-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
}
.jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
    background-image: repeating-linear-gradient(transparent 0, transparent 37px, var(--jodit-editor-toolbar-border-color, inherit) 38px) !important;
}
.jodit-ui-group {
    border-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
}
.jodit-ui-group_separated_true:not(:last-child):after {
    border-color: var(--jodit-editor-toolbar-border-color, inherit) !important;
}
.jodit-toolbar__box:not(:empty) {
    border-bottom: none;
}

// Main workplace
.jodit_theme_custom.jodit-container:not(.jodit_inline) .jodit-workplace {
    flex: 1 1 auto;
    background-color: var(--jodit-editor-workplace-background-color, inherit);
    color: var(--jodit-editor-workplace-color, inherit);
    border-color: var(--jodit-editor-workplace-border-color, inherit);
}
.jodit_theme_custom.jodit-container .jodit-workplace > .jodit-wysiwyg {
    flex: 1 1 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

// Status bar
.jodit_theme_custom .jodit-status-bar {
    flex: 0 0 auto;
    background-color: var(--jodit-editor-statusbar-background-color, inherit) !important;
    color: var(--jodit-editor-statusbar-color, inherit) !important;
    border-color: var(--jodit-editor-statusbar-border-color, inherit) !important;
    fill: var(--jodit-editor-statusbar-color);
}
.jodit_theme_custom .jodit-workplace + .jodit-status-bar {
    border-color: var(--jodit-editor-statusbar-border-color, inherit) !important;
}
