/* SCSS for controlling the scrollbar */

.scrollable {
    overflow: auto;
}

.scrollable-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.scrollable-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.not-scrollable {
    overflow: hidden;
}
